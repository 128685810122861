import Vue from "vue";
import Vuex from "vuex";
import authentication from "@/store/modules/authentication";
import schedules from "@/store/modules/schedules";
import themer from "@xtreme-vue-utils/themer";
import featureFlags from "./modules/featureFlags";

Vue.use(Vuex);

export const storeModel = {
    state: {
        notifications: [],
        connected: false,
        status: [],
        lighting: [],
        hvac: [],
        redirect: undefined,
        focusRoom: "",
        staticRoom: "",
        welcomeBtnConf: [],
        goodbyeBtnConf: [],
        blueLightFilter: 8,
        defaultWelcomeRooms: ["Kitchen", "Hallway", "Living Room"],
        hvacSchedules: [],
        activeHVACSchedules: [],
        countdownTarget: {
            target: undefined,
        },
        killSwitch: {
            live: false,
        },
        currentSeasonalTheme: undefined,
        seasonalThemeDetails: [{
            name: "newyears",
            start: "31/12",
            end: "31/12",
            button: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/newyears/rocket.png",
            left: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/newyears/balloon-left.png",
            right: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/newyears/balloon-right.png",
            confettiConfig: {
                particles: [
                    {
                        type: "rect",
                        colors: [
                            "red",
                            "green",
                            "yellow",
                            "blue",
                        ],
                    },
                ],
                defaultSize: 4,
                defaultDropRate: 8,
                particlesPerFrame: 4,
                windSpeedMax: 0.1,
            },
        },
        {
            name: "thanksgiving",
            start: "27/11",
            end: "27/11",
            button: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/thanks-giving.png",
            left: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaves-left.png",
            right: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaves-right.png",
            confettiConfig: {
                particles: [
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf1.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf2.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf3.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf4.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf5.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/thanksgiving/leaf6.png",
                    },
                ],
                defaultSize: 12,
                defaultDropRate: 5,
                particlesPerFrame: 1,
                windSpeedMax: 1,
            },
        },
        {
            name: "easter",
            start: "20/04",
            end: "20/04",
            button: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/eggs.png",
            left: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter-left.png",
            right: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter-right.png",
            confettiConfig: {
                particles: [
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter1.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter2.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter3.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter4.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter5.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/easter/easter6.png",
                    },
                ],
                defaultSize: 12,
                defaultDropRate: 6,
                particlesPerFrame: 0.4,
                windSpeedMax: 0,
            },
        },
        {
            name: "halloween",
            start: "31/10",
            end: "31/10",
            button: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/pumpkin-1.png",
            left: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/slime-left.png",
            right: "",
            confettiConfig: {
                particles: [
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 4,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 2,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 2,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 4,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 2,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                        size: 2,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/drip.png",
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/bones3.png",
                        size: 10,
                        dropRate: 20,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/halloween/bone1.png",
                        size: 11,
                        dropRate: 20,
                    },
                ],
                defaultSize: 6,
                defaultDropRate: 20,
                particlesPerFrame: 1,
                windSpeedMax: 0,
            },
        },
        {
            name: "christmas",
            start: "23/12",
            end: "26/12",
            button: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/button.png",
            left: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/left.png",
            right: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/right.png",
            confettiConfig: {
                particles: [
                    {
                        type: "circle",
                        colors: [
                            "white",
                        ],
                        size: 3,
                        dropRate: 3,
                    },
                    {
                        type: "circle",
                        colors: [
                            "white",
                        ],
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/peepee1.png",
                        size: 3,
                        dropRate: 15,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/peepee2.png",
                        size: 2,
                        dropRate: 15,
                    },
                    {
                        type: "image",
                        url: "https://xcai-ftp.fra1.cdn.digitaloceanspaces.com/images/zeeb/seasonalThemes/christmas/snowflake.png",
                        size: 6,
                        dropRate: 15,
                    },
                ],
                defaultSize: 6,
                defaultDropRate: 5,
                particlesPerFrame: 3,
                windSpeedMax: 0.9,
            },
        }],
        seasonalThemeState: false,
        countdownDevice: false,
        networkStats: {
            download: "-",
            upload: "-",
            latency: "-",
            date: "",
        },
        tvList: [],
    },
    mutations: {
        updateFilter(state, val) {
            state.blueLightFilter = val;
        },

        updateRoom(state, feedback) {
            for (const room of feedback) {
                if (room === null) return;
                const index = state.status.findIndex((r) => r?.id === room.id);
                if (index === -1) {
                    Vue.set(state.status, state.status.length, room);
                } else {
                    Vue.set(state.status, index, room);
                }
            }

            this.commit("generateSubsystems", state.status);
        },

        generateSubsystems(state, status) {
            const tempStatus = status.sort((a, b) => a?.name?.localeCompare(b?.name));

            const lighting = [];
            const hvac = [];
            for (let i = 0; i < tempStatus.length; i += 1) {
                if (Array.isArray(tempStatus[i]?.status?.lighting)) {
                    const roomLights = tempStatus[i]?.status?.lighting;
                    const power = roomLights?.some((r) => (r?.value !== "Off") && r?.percentage !== 0);
                    lighting.push({
                        Number: tempStatus[i]?.id,
                        Room: tempStatus[i]?.name,
                        Power: power || "Off",
                        Lighting: roomLights,
                        State: true,
                    });
                }
                if (Array.isArray(tempStatus[i]?.status?.ac)) {
                    const roomAC = tempStatus[i]?.status?.ac[0];
                    hvac.push({
                        Number: roomAC?.name,
                        Room: tempStatus[i]?.name,
                        Power: roomAC?.hvac,
                        Current: roomAC?.temperature,
                        SetTemp: roomAC?.setPoint,
                        State: roomAC?.state,
                        Min: roomAC?.acLower,
                        Max: roomAC?.acUpper,
                    });
                }
            }

            state.lighting = lighting;
            state.hvac = hvac;

            this.commit("firstTimeConfigs");
        },
        updateFeatures(state, features) {
            state.features = features;
        },

        updateNotifications(state, notifications) {
            state.notifications = notifications;
        },

        setFocusedRoom(state, room) {
            state.focusRoom = room;
        },

        setStaticRoom(state, room) {
            state.staticRoom = room;
        },

        setRedirect(state, redirect) {
            state.redirect = redirect;
        },

        firstTimeConfigs(state) {
            state.welcomeBtnConf = [];
            state.goodbyeBtnConf = [];
            for (let i = 0; i < state.lighting.length; i += 1) {
                if (state.defaultWelcomeRooms.some((s) => state.lighting[i].Room.includes(s))) {
                    state.welcomeBtnConf.push(i);
                }
                state.goodbyeBtnConf.push(i);
            }
        },
        handleLockouts(state, data) {
            if (data.killSwitch) {
                state.killSwitch = data.killSwitch;
                return;
            }
            state.countdownTarget = data;
        },

        // LOOK HERE
        setEvents(state, events) {
            state.events = events;
        },
        updateEvents(state, events) {
            state.eventDates = events;
        },
        enableCountdown(state) {
            state.countdownDevice = true;
        },
        updateNetworkStats(state, stats) {
            if (stats.latency) {
                localStorage.setItem("scan", stats);
                state.networkStats = stats;
            }
        },
        clearNetworkStats(state, stats) {
            state.networkStats = stats;
        },

        updateTVList(state, data) {
            state.tvList = data;
        },

        setSeasonalThemeState(state) {
            state.seasonalThemeState = !state.seasonalThemeState;
        },

        setCurrentSeasonalTheme(state, seasonalTheme) {
            state.currentSeasonalTheme = seasonalTheme;
        },

        updateSeasonalThemes(state, themes) {
            if (themes.length) {
                state.seasonalThemeDetails = themes;
            }
        },
    },
    actions: {
        async updateStatus(context, msg) {
            await context.commit("updateRoom", [msg]);
        },
        receivedFeedback({ commit, dispatch }, msg) {
            if (!msg?.data?.[0]) return;

            switch (msg.type) {
                case "feedback":
                case "cache":
                    if (msg?.meta?.context === "configs") {
                        dispatch("handleConfigs", msg);
                    } else if (msg?.meta?.event) {
                        commit("updateEvents", msg.data);
                    } else if (msg?.meta?.lockout) {
                        commit("handleLockouts", msg.data[0]);
                    } else if (msg?.data) {
                        commit("updateRoom", msg.data);
                    }
                    break;
                case "network":
                    commit("updateNetworkStats", msg.data[0]);
                    break;
                case "notifications":
                    if (!msg.action) {
                        commit("updateNotifications", msg.data);
                    }
                    break;
                default:
                    break;
            }
        },

        handleConfigs({ dispatch, commit }, msg) {
            if (msg.meta.id.includes("schedule")) {
                dispatch("schedules/updateSchedules", msg);
            } else if (msg.meta.id === "tvs") {
                commit("updateTVList", msg.data);
            } else if (msg.meta.id === "seasonal-themes") {
                commit("updateSeasonalThemes", msg.data);
            }
        },

        getRoomFeedback({ dispatch }) {
            dispatch("authentication/send", {
                meta: {
                    id: "*",
                },
                type: "feedback",
                action: "get",
            });
        },
        updateFocus(context, msg) {
            context.commit("setFocusedRoom", msg);
        },
        setStaticRoom(context, msg) {
            context.commit("setStaticRoom", msg);
        },
        updateRedirect(context, msg) {
            context.commit("setRedirect", msg);
        },
        setupLocations() {
        },
        sendCountdown({ commit, dispatch }, target) {
            commit("handleLockouts", target);
            dispatch("authentication/send", {
                meta: {
                    lockout: true,
                    id: target.room,
                },
                type: "lockouts",
                action: "update",
                data: [target],
            });
        },
        getCountdownFromServer({ dispatch }) {
            dispatch("authentication/send", {
                meta: {
                    id: "*",
                    lockout: true,
                },
                type: "lockouts",
                action: "get",
            });
        },
        scanNetwork({ dispatch, commit }) {
            commit("clearNetworkStats", {
                upload: "",
                download: "",
                latency: "",
            });
            dispatch("authentication/send", {
                meta: {
                    context: "bandwidth",
                },
                type: "network",
                action: "get",
            });
        },
        getNetworkScanResults({ commit, dispatch }) {
            const localScan = localStorage.getItem("scan");
            if (localScan) {
                commit("updateNetworkStats", localScan); // locally stored for immediate access to prevent loading wheels on app load
            }
            dispatch("authentication/send", {
                meta: {
                    context: "getFeedback",
                },
                type: "network",
                action: "get",
            });
        },
        sendClearCountdown({ commit, dispatch }) {
            commit("handleLockouts", { target: null });
            dispatch("authentication/send", {
                meta: {
                    lockout: true,
                },
                type: "lockouts",
                action: "delete",
            });
        },
        seasonalThemeButtonToggle({ commit }, value) {
            commit("toggleSeasonalThemeBtn", value);
        },
        updateEvents({ commit }, events) {
            commit("setEvents", events);
        },
        updateSeasonalThemeAnimation({ commit }, src) {
            commit("setSeasonalThemeAnimationSrc", src);
        },
        updateClickedAnimation({ commit }, src) {
            commit("setClickedAnimationSrc", src);
        },
        setupDeviceAsCountdown({ commit }) {
            commit("enableCountdown");
        },
        feedbackInit({ dispatch }) {
            if (!localStorage.getItem("location")) {
                const location = process.env.VUE_APP_LOCATION || "$LOCATION";
                localStorage.setItem("location", location);
            }
            dispatch("getTVConfig");
            dispatch("getRoomFeedback");
            dispatch("getCountdownFromServer");
            dispatch("getNetworkScanResults");
        },
        sendKillCommandToService({ dispatch }, msg) {
            dispatch("authentication/send", {
                meta: {
                    lockout: true,
                },
                type: "lockouts",
                action: "create",
                data: [msg],
            });
        },
        getTVConfig({ dispatch }) {
            dispatch("authentication/send", {
                meta: {
                    location: localStorage.getItem("location") || process.env.VUE_APP_LOCATION || "$LOCATION",
                    context: "configs",
                    id: "tvs",
                },
                type: "cache",
                action: "get",
            });
        },

        setSeasonalThemeState({ commit }) {
            commit("setSeasonalThemeState");
        },

        setCurrentSeasonalTheme({ commit }, value) {
            commit("setCurrentSeasonalTheme", value);
        },
        getSeasonalThemes({ dispatch }) {
            dispatch("authentication/send", {
                meta: {
                    location: localStorage.getItem("location") || process.env.VUE_APP_LOCATION || "$LOCATION",
                    context: "configs",
                    id: "seasonal-themes",
                },
                type: "cache",
                action: "get",
            });
        },
    },
    getters: {
        accessLevel: (state) => state.authentication.xAuth?.user?.accessLevel,
        getHVACSchedules: (state) => state.hvacSchedules,
        getActiveHVACSchedules: (state) => state.activeHVACSchedules,
        countdownTarget: (state) => state.countdownTarget,
        countdownDevice: (state) => state.countdownDevice,
        getStaticRoom: (state) => state.staticRoom,
        killSwitch: (state) => state.killSwitch,
    },
    modules: {
        authentication,
        themer,
        schedules,
        featureFlags,
    },
};

export default new Vuex.Store(storeModel);
