/* istanbul ignore file */

// Allowed meta keys:
// hideNavigation (boolean): default is false, true will hide
// disableNavigation (boolean): default is false, true will disable all nav options
// requireAuth (boolean): default is false, true will force login
// accessLevel (int): will hide from menu and display 401 access denied if trying to access
// menu (object): with icon and text properties, if object exists it will be added to the menu (filtered by access level)
import { getEnvString } from "../envUtils";

const isLocalMode = process.env.NODE_ENV === "development" || (getEnvString("$VUE_APP_LOCALMODE", "false") !== "false");

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login/login.vue"),
        meta: {
            disableNavigation: true,
            disabled: !isLocalMode,
        },
    },
    {
        path: "/registration",
        name: "registration",
        component: () => import(/* webpackChunkName: "registration" */ "../views/login/registration.vue"),
        meta: {
            disableNavigation: true,
            disabled: !isLocalMode,
        },
    },
    {
        path: "/reset",
        name: "reset",
        component: () => import(/* webpackChunkName: "reset" */ "../views/login/reset.vue"),
        meta: {
            disableNavigation: true,
            disabled: !isLocalMode,

        },
    },
    {
        path: "/MultiFactorAuth",
        name: "MultiFactorAuth",
        component: () => import(/* webpackChunkName: "multifactorauth" */ "../views/login/multi-factor-auth.vue"),
        props: true,
        meta: {
            disableNavigation: true,
            disabled: !isLocalMode,
        },
    },
    {
        path: "/401",
        name: "401",
        component: () => import(/* webpackChunkName: "401" */ "../views/401.vue"),
        meta: {},
    },
    {
        path: "/clock",
        name: "clock",
        component: () => import(/* webpackChunkName: "clock" */ "../views/clock.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/main-menu",
        name: "main-menu",
        alias: "/",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/main-menu.vue"),
        meta: {
            menu: {
                icon: "mdi-home-outline",
                text: "Home",
            },
            disabled: false,
            requiresAuth: !isLocalMode,
        },
    },
    {
        path: "/lighting",
        name: "lighting",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/lighting.vue"),
        meta: {
            menu: {
                icon: "mdi-lightbulb-outline",
                text: "Lighting",
            },
            requiresAuth: !isLocalMode,
        },
    },
    {
        path: "/hvac",
        name: "hvac",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/hvac.vue"),
        meta: {
            menu: {
                icon: "mdi-weather-dust",
                text: "HVAC",
            },
            requiresAuth: !isLocalMode,
        },
    },
    {
        path: "/cameras",
        name: "cameras",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/cameras.vue"),
        meta: {
            menu: {
                icon: "mdi-video-outline",
                text: "Cameras",
            },
            disabled: true,
            requiresAuth: !isLocalMode,
        },
    },
    {
        path: "/intercom",
        name: "intercom",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/intercom.vue"),
        meta: {
            menu: {
                icon: "mdi-dots-grid",
                text: "Intercom",
            },
            disabled: true,
            requiresAuth: !isLocalMode,
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "hvac" */ "../views/settings.vue"),
        meta: {
            menu: {
                icon: "mdi-menu",
                text: "Settings",
            },
            disabled: true,
            requiresAuth: !isLocalMode,
        },
    },
];

export default routes;
