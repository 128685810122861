import XAuth from "@xtreme-controls/xtreme-authentication";
import { getEnvString } from "../../envUtils";
import { version } from "../../../package.json";

export const mutations = {
    updateXAuth(state, xAuth) {
        state.xAuth = xAuth;
    },

    connected(state, connected) {
        state.connected = connected;
    },

    disconnected(state) {
        state.connected = false;
    },

    refreshInterval(state, interval) {
        clearInterval(state.refreshInterval);
        state.refreshInterval = undefined;
        if (interval) {
            state.refreshInterval = interval;
        }
    },
};

export const actions = {
    async login(context, loginPacket) {
        if (context.state.xAuth.loggedIn()) {
            return true;
        }
        return context.state.xAuth.login(loginPacket.username, loginPacket.password);
    },

    async logout(context) {
        localStorage.clear();
        await context.state.xAuth.logout();
    },

    async send(context, msg) {
        const message = msg;
        if (!context.state.xAuth) {
            context.dispatch("initialise");
            await new Promise((resolve) => {
                setTimeout(() => resolve(), 750);
            });
        }

        if (message) {
            message.meta = {
                ...message.meta,
                version,
                roomName: context.rootGetters.getStaticRoom,
                location: !message.meta?.location ? localStorage.getItem("location") : message.meta.location,
            };
        }
        return context.state.xAuth.send(message);
    },

    async connectionUpdate(context, status) {
        context.commit("connected", status);
        if (!status && !context.state.refreshInterval) {
            const interval = setInterval(async () => {
                console.info("reinitialisig @", Date.now());
                context.commit("updateXAuth", undefined);
                context.dispatch("initialise");
            }, 1000);
            context.commit("refreshInterval", interval);
        } else if (status && context.state.refreshInterval) {
            context.commit("refreshInterval");
        }
    },

    initialise(context) {
        if (!context.state.xAuth) {
            try {
                const { hostname } = window.location;
                const wsPort = getEnvString("$VUE_APP_WS_PORT", "");

                // Adds a port for testing on localhost
                let wsHost = `${hostname}/ws/app/`;
                if (wsPort) {
                    wsHost = `${hostname}:${wsPort}/ws/app/`;
                }

                const isLocalMode = getEnvString("$VUE_APP_LOCALMODE", "false") !== "false";

                const xAuthInstance = new XAuth(wsHost, { requireSmsVerification: true, localmode: isLocalMode }, (msg) => {
                    context.dispatch("receivedFeedback", msg, { root: true });
                });

                xAuthInstance.on("logged-out", () => {
                    context.dispatch("logout");
                    context.commit("disconnected");
                });

                xAuthInstance.on("connected", (connected) => {
                    console.info("websocket connection status", connected, "@", Date.now());
                    context.dispatch("connectionUpdate", connected);
                    context.dispatch("feedbackInit", { root: true });
                });

                if (isLocalMode) {
                    console.info("attempting to login localmode");
                    xAuthInstance.login();
                }

                context.commit("updateXAuth", xAuthInstance);
            } catch (err) {
                console.error("xAuth Connection Error", err);
            }
        }
    },
};

export default {
    namespaced: true,
    state: () => ({
        xAuth: undefined,
    }),
    mutations,
    actions,
};
