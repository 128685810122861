import axios from "axios";
import { getEnvString } from "../../envUtils";

export default {
    namespaced: true,
    state: () => ({
        flagList: undefined,
    }),
    mutations: {
        updateFlags(state, flag) {
            state.flagList = {
                ...state.flagList,
                ...flag,
            };
            console.info("currentFlag", JSON.stringify(state.flagList));
        },
    },

    actions: {
        async getFlag({ commit }, flagKey) {
            const http = (getEnvString("$VUE_APP_LOCALMODE", "false") !== "false") ? "http" : "https";

            const url = `${http}://${window.location.hostname}/flipt/evaluate/v1/boolean`;
            const payload = {
                context: {},
                flagKey,
                entityId: "1",
                namespaceKey: "default",
            };

            const options = {
                headers: {
                    "content-type": "application/json",
                },
            };
            try {
                const response = await axios.post(url, payload, options);
                if (response?.data?.flagKey) {
                    const flag = {};
                    flag[response.data.flagKey] = response.data.enabled;
                    commit("updateFlags", flag);
                }
            } catch (error) {
                console.error(`Error logging in: ${error}`);
            }
        },
    },
    getters: {
        includesFeature: (state) => (flag) => !!state.flagList?.[flag],
    },
};
