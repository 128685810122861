import Vue from "vue";
import IdleVue from "idle-vue";
import VueConfetti from "vue-confetti";
import xtremeVC from "@xtreme-controls/vue-components";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import packageJson from "../package.json";
import Ybug from "./ybug";

const { version } = packageJson;
console.info(`App Version: ${version}`);

Vue.config.productionTip = false;

router.beforeEach(async (to, _from, next) => next());

Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    idleTime: 60000, // 1 mins
});

Vue.use(VueConfetti);

Vue.use(xtremeVC);

Vue.use(Ybug, {
    id: "7mrh31vf9chjffgz88w5",
    settings: {
        hide_launcher: true,
    },
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
