import Vue from "vue";
import VueRouter from "vue-router";
import { loginGuard, accessGuard } from "@xtreme-vue-utils/router";
import store from "../store";
import routes from "./routes";

store.dispatch("authentication/initialise");

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const { xAuth } = store.state.authentication;

router.beforeEach(loginGuard(xAuth, "/login"));
router.beforeEach(accessGuard(xAuth, "/401"));

export default router;
