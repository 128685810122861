export default {
    namespaced: true,
    state: () => ({
        schedules: {
            hvac: [],
            lockout: [],
        },
    }),
    mutations: {
        addSchedule(state, { type, scheduleObject }) {
            state.schedules[type].push(scheduleObject);
        },
        editSchedule(state, { type, index, scheduleObject }) {
            state.schedules[type][index] = scheduleObject;
        },
        deleteSchedule(state, { type, index }) {
            state.schedules[type].splice(index, 1);
        },
        activateSchedule(state, { type, index }) {
            state.schedules[type][index].active = !state.schedules[type][index].active;
        },
        setSchedules(state, { type, schedules }) {
            const filterSchedules = schedules.filter((s) => s.repeatRate);
            state.schedules[type] = filterSchedules;
        },
    },
    actions: {
        getSchedulesConfig({ dispatch }, { type }) {
            dispatch("authentication/send", {
                meta: {
                    location: localStorage.getItem("location") || process.env.VUE_APP_LOCATION || "$LOCATION",
                    context: "configs",
                    id: `${type}-schedule`,
                },
                type: "cache",
                action: "get",
            }, { root: true });
        },
        updateSchedules({ commit }, msg) {
            const type = msg.meta.id.replace("-schedule", "");
            const schedules = msg.data;
            commit("setSchedules", { type, schedules });
        },
        addSchedule({ commit, dispatch }, schedule) {
            commit("addSchedule", schedule);
            dispatch("sendSchedule", schedule);
        },
        editSchedule({ commit, dispatch }, schedule) {
            commit("editSchedule", schedule);
            dispatch("sendSchedule", schedule);
        },
        deleteSchedule({ commit, dispatch }, schedule) {
            commit("deleteSchedule", schedule);
            dispatch("sendSchedule", schedule);
        },
        activateSchedule({ commit, dispatch }, schedule) {
            commit("activateSchedule", schedule);
            dispatch("sendSchedule", schedule);
        },
        sendSchedule({ dispatch, state }, { type }) {
            dispatch("authentication/send", {
                meta: {
                    location: localStorage.getItem("location") || process.env.VUE_APP_LOCATION || "$LOCATION",
                    context: "configs",
                    id: `${type}-schedule`,
                },
                type: "cache",
                action: "create",
                data: state.schedules?.[type],
            }, { root: true });
        },
    },
    getters: {
        getSchedules: (state) => (type) => state.schedules[type],
    },
};
