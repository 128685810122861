<template>
    <v-navigation-drawer
        v-if="!hideNav"
        v-model="showNavMenu"
        class="nav-drawer"
        data-test="nav-drawer"
        color="#38373d"
        app
        mobile-breakpoint="960"
        mini-variant
        mini-variant-width="160"
        :touchless="true"
    >
        <v-list class="d-flex flex-column flex-grow nav-list">
            <v-list-item
                v-for="(navItem, i) in navItems"
                :key="i"
                v-ripple
                :to="navItem.path"
                active-class="active-nav-item"
                :disabled="navItem.meta.disabled || disableNav"
                :data-test="`sub-nav-item`"
                link
                justify="center"
                align="center"
                class="nav-item d-flex justify-center align-center"
            >
                <v-list-item-icon class="center-icon">
                    <v-icon :size="55">{{ navItem.meta.menu.icon }}</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import packageJson from "../../package.json";

const { version } = packageJson;

export default {
    props: {
        xAuth: {
            type: Object,
            default() {
                return {};
            },
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showNavMenu: this.show,
            appVersion: version,
        };
    },
    computed: {
        hideNav() {
            return this.$route?.meta?.hideNavigation || false;
        },
        disableNav() {
            return this.$route?.meta?.disableNavigation || false;
        },
        navItems() {
            // We are only interested in routes that have menu meta data and that we have access too.
            // const allRoutes = this.$router?.options?.routes;
            const routes = this.$router?.getRoutes()?.filter((r) => {
                const isMenuItem = r?.meta?.menu && Object.keys(r?.meta?.menu).length > 0;
                const unrestrictedPage = r?.meta?.accessLevel === undefined;
                const hasAccess = this.xAuth.user?.accessLevel <= r?.meta?.accessLevel;
                return isMenuItem && (unrestrictedPage || hasAccess);
            });

            return routes;
        },
    },
    watch: {
        show(newValue) {
            this.showNavMenu = newValue;
        },
        showNavMenu(newValue) {
            this.$emit("updateNav", newValue);
        },
    },
};
</script>

<style>
.nav-list {
    height: 100%;
}

.center-icon {
    margin: auto !important;
}
.nav-drawer {
    background-color: #38373d !important;
}
.nav-item {
    margin: 10% !important;
    background-color: transparent !important;
}

.active-nav-item {
    background: linear-gradient(0deg, rgb(123,122,124), rgba(255,255,255) 200%) !important;
    outline: 2px solid #4BAEEC !important;
    border-radius: 4% !important;
}
</style>
