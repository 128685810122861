const scriptUrl = (id) => `https://widget.ybug.io/button/${id}.js`;

let isInitialized = false;

const initYbug = (id, settings) => {
    window.ybug_settings = {
        id,
        ...settings,
        onload() {
            // eslint-disable-next-line no-unused-expressions
            settings && settings.onload && settings.onload();
        },
    };

    if (!isInitialized) {
        const script = document.createElement("script");
        script.async = true;
        if (settings.nonce) {
            script.setAttribute("nonce", settings.nonce);
        }
        script.src = scriptUrl(id);
        document.head.appendChild(script);
        isInitialized = true;
    }
};

/**
 * Install Ybug's Feedback Widget into your app
 *
 * @param Vue      Vue
 * @param id       Ybug ID
 * @param settings Additional settings (@see https://ybug.io/docs/installation#installation)
 */
const install = (Vue, { id, settings }) => {
    if (!id) {
        throw new Error("Please provide a Ybug Project ID");
    }

    initYbug(id, settings);

    const ybugApi = {
        boot() { return window.Ybug && window.Ybug.boot(); },
        show(arg) { return window.Ybug && window.Ybug.show(arg); },
        hide(arg) { return window.Ybug && window.Ybug.hide(arg); },
        open(arg) { return window.Ybug && window.Ybug.open(arg); },
        destroy() { return window.Ybug && window.Ybug.destroy(); },
        close() { return window.Ybug && window.Ybug.close(); },
        on(evt, callback) { return window.Ybug && window.Ybug.on(evt, callback); },
        log(type, msg) { return window.Ybug && window.Ybug.log(type, msg); },
        setUser(arg) { return window.Ybug && window.Ybug.setUser(arg); },

        /**
         * Init method can be used to change the settings object.
         * @param settings
         */
        // eslint-disable-next-line no-shadow
        init(settings) {
            initYbug(id, settings);
            this.destroy();
            this.boot();
        },
    };

    // make $ybug property available
    // to all components using Options API
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ybug = ybugApi;

    Vue.mixin({
        beforeCreate() {
            this.$options.ybug = this.$root.$options.ybug;
        },
    });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ybug = ybugApi;
};

export default {
    install,
};
