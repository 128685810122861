<template>
    <v-app>
        <Countdown v-if="showCountdown" :target="countdownTarget" :set="!!countdownTarget.target" :absolute="true" data-test="countdown-component" />
        <div class="circadian-filter" :style="{ 'opacity': getFilterOpacity }" data-test="circadian-filter" />
        <div v-if="disableNav" class="pos-a h-100 w-100 z-10 noclick disabled-cover" data-test="disabled-cover" />
        <v-app-bar v-if="!hideNav" class="dash-app-bar" app flat color="#23262c" dark>
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" data-test="app-bar-btn" color="#AFB9C4" @click="showNavMenu = !showNavMenu">
                <v-icon>mdi-menu</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer />
            <v-toolbar-title data-test="title-bar-time" class="dash-app-bar-title">{{ getTime }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-title data-test="app-bar-date" class="dash-app-bar-title">{{ getDate }}, Week {{ getWeekNumber }}</v-toolbar-title>
        </v-app-bar>
        <NavDrawer :x-auth="xAuth" :show="showNavMenu" class="z-250" @updateNav="(val) => showNavMenu = val" />
        <v-main :class="disableNav ? 'z-100' : ''">
            <div v-if="loading" style="width: 100%; height: 100%;" class="d-flex justify-center align-center">
                <img class="spinner" src="./assets/icons/spinner.svg" alt="spinner">
            </div>
            <router-view v-else />
            <div class="version">v.{{ version }}</div>
        </v-main>
    </v-app>
</template>

<script>
import spacetime from "spacetime";
import daylight from "spacetime-daylight";
import currentWeekNumber from "current-week-number";
import { mapActions, mapState, mapGetters } from "vuex";
import NavDrawer from "@/components/NavDrawer.vue";
import store from "@/store";
import seasonalThemer from "@/mixins/seasonalThemer";
import packageJson from "../package.json";
import Countdown from "./components/LockoutTimer/Countdown.vue";
// // eslint-disable-next-line import/no-relative-packages
// import mockData from "../xcore-documentation/packets/air/roomFeedback.json";
// // eslint-disable-next-line import/no-relative-packages
// import mockHVACSchedule from "../xcore-documentation/packets/air/hvacSavedSchedules.json";
// // eslint-disable-next-line import/no-relative-packages
// import mockLockoutSchedule from "../xcore-documentation/packets/air/lockoutSavedSchedules.json";
// // eslint-disable-next-line import/no-relative-packages
// import mockLockoutTVConfig from "../xcore-documentation/packets/air/lockoutTVsConfig.json";

const { version } = packageJson;

spacetime.extend(daylight);

export default {
    components: {
        NavDrawer,
        Countdown,
    },
    mixins: [seasonalThemer],
    data() {
        return {
            showNavMenu: true,
            version,
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            now: Date.now(),
            loading: false,
            idle: false,
            filterTimer: {
                beforeSunset: 180,
                afterSunset: 0,
                beforeSunrise: 120,
                afterSunrise: 0,
            },
            filterConfig: {
                FullOnTime: 22,
                FullOffTime: 8,
                beforeUpMins: 180,
                beforeDownMins: 120,
            },
        };
    },
    computed: {
        ...mapState("authentication", ["xAuth"]),
        ...mapState(["blueLightFilter", "redirect"]),
        ...mapGetters(["countdownTarget", "countdownDevice"]),

        hideNav() {
            return this.$route?.meta?.hideNavigation || false;
        },

        disableNav() {
            return this.$route?.meta?.disableNavigation || false;
        },

        getSpaceTime() {
            return spacetime(this.now, this.tz);
        },

        getTime() {
            return this.getSpaceTime.time().toUpperCase();
        },

        getDate() {
            return this.getSpaceTime.format("{day}, {date-pad} {month} {year}");
        },

        getWeekNumber() {
            return currentWeekNumber();
        },

        getFilterOpacity() {
            const today = new Date();
            const todayMins = ((today.getHours() * 60) + today.getMinutes());

            const timeToFullOnMins = (this.filterConfig.FullOnTime * 60) - todayMins;

            const timeToFullOffMins = (this.filterConfig.FullOffTime * 60) - todayMins;

            let opacity = this.maxOpacity;

            if (timeToFullOnMins < this.filterConfig.beforeUpMins && timeToFullOnMins > 0) {
                opacity = this.maxOpacity - ((this.maxOpacity / this.filterConfig.beforeUpMins) * timeToFullOnMins);
            } else if (timeToFullOffMins < this.filterConfig.beforeDownMins && timeToFullOffMins > 0) {
                opacity = (this.maxOpacity / this.filterConfig.beforeDownMins) * timeToFullOffMins;
            } else if (timeToFullOnMins > this.filterConfig.beforeUpMins && timeToFullOffMins <= 0) {
                opacity = 0;
            }

            return opacity;
        },

        maxOpacity() {
            if (this.blueLightFilter && this.blueLightFilter !== "undefined") {
                return this.blueLightFilter / 100;
            }
            return 0.08;
        },

        routeCheck() {
            const excludedRoutes = ["login", "registration", "reset", "MultiFactorAuth", "clock"];
            return !excludedRoutes.includes(this.$route?.name);
        },

        showCountdown() {
            if (!this.countdownTarget?.target) {
                return false;
            }

            const lessThanThirtyMin = ((this.countdownTarget.target - this.now) < (1000 * 60 * 30));
            const greaterThanMinusFiveSec = ((this.countdownTarget.target - this.now) > -5000);
            return lessThanThirtyMin && greaterThanMinusFiveSec && this.countdownDevice && this.$route.name !== "clock";
        },
    },

    watch: {
        $route() {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 1);
        },
    },

    async mounted() {
        // this.$store.commit("updateRoom", mockData?.data);
        // store.dispatch("receivedFeedback", mockHVACSchedule);
        // store.dispatch("receivedFeedback", mockLockoutSchedule);
        // store.dispatch("receivedFeedback", mockLockoutTVConfig);

        store.dispatch("themer/activateTheme", ({
            $vuetify: this.$vuetify,
            theme: { dark: true },
        }));
        setInterval(() => {
            this.now = Date.now();
        }, 1000);
        await this.feedbackInit();

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        this.handleQueryParams({ ...params, ...this.$route?.query });

        document.title = "Air";

        const blueLightFilter = localStorage.getItem("blueLightFilter");
        if (blueLightFilter) {
            this.$store.commit("updateFilter", blueLightFilter);
        }
    },

    async onIdle() {
        this.idle = true;
        if (document.getElementById("__ybug-launcher")?.style) {
            document.getElementById("__ybug-launcher").style.opacity = 0;
        }

        if ((this.redirect && this.$route?.name !== this.redirect)) {
            await this.$router?.push({ name: this.redirect });
        } else if (this.routeCheck && this.$route?.name !== "main-menu") {
            await this.$router?.push({ name: "main-menu" });
        }
    },

    onActive() {
        this.idle = false;
        document.getElementById("__ybug-launcher").style.opacity = 1;
    },

    methods: {
        ...mapActions("authentication", ["initialise", "send"]),
        ...mapActions("themer", ["activateTheme"]),
        ...mapActions(["feedbackInit", "updateRedirect", "updateFocus", "setStaticRoom", "setupDeviceAsCountdown"]),

        handleQueryParams(params) {
            if (!params) {
                return;
            }

            console.info("handle params", params);
            const { redirect, room, countdown } = params;

            if (redirect) {
                this.updateRedirect(redirect);
            }

            if (countdown) {
                this.setupDeviceAsCountdown();
            }

            if (room) {
                this.setStaticRoom(room);
                this.updateFocus(room);
                if (room === "boys_bedroom") {
                    this.$router.push({ name: "clock" });
                }
            }
        },
    },
};
</script>

<style>
/*  https://bugs.webkit.org/show_bug.cgi?id=231161
    Currently there is a bug in iOS15 that prevents the webkit-user-select: none from working correctly
    It prevents selection of the text on the web page but the Magnifier still pops up and the haptic feedback still thuds.
    This is reported in the above bug report and as of 25/11/2021 has not been rolled into the latest public beta iOS 15.2
    it is likley to be rolled into 15.3 when that is released.
*/

@font-face {
  font-family: 'Roboto';
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz4dL_nz.woff2) format('woff2');
}
@font-face {
  font-family: 'Minim';
  src: url("../public/fonts/minim/minim.otf") format('truetype');
}
:root {
    -webkit-user-select: none;
    user-select: none;
    font-family: "Roboto", sans-serif;
}

.bottom-bar {
    display: none !important;
}

.version {
    position: absolute;
    bottom: 1px;
    right: 5px;
    font-size: 10px;
    color: #AFB9C4;
}

.circadian-filter {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    background-color: rgb(66, 0, 0);
    z-index: 10000;
    pointer-events: none;
}
.disabled-cover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Global Styles */
.dash-app-bar {
    border-bottom: none;
    height: 64px !important;
}

.dash-app-bar-title {
    color: #AFB9C4;
}

.dash-app-bar-title-room{
    color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
    .dash-app-bar-title {
        font-size: 0.9em;
    }
    .dash-app-bar-title-room{
        font-size: 1.4em;
    }
}

.main-window {
    width: calc(100vw - 160px);
    height: calc(100vh - 64px);
    padding: 0 24px 24px;
    overflow: hidden;
}

@media only screen and (max-width: 960px) {
    .main-window {
        width: 100%;
        overflow: auto;
    }
}

.config-card {
    border-top: 1px solid #23262c !important;
}

.config-btn {
    background-color: #4E4E52 !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 50px !important;
    width: 50px !important;
}

.fullheight-card {
    max-height: 1100px;
    height: calc(100vh - 90px);
}

.fullheight-wconfig-card {
    max-height: 1100px;
    height: calc(100vh - 190px);
}

.force-caret-colour {
    color: #afb9c4 !important;
    caret-color: #afb9c4 !important;
}

.noclick {
  pointer-events: none !important;
}

.scrollbox-full {
    overflow-y: auto !important;
    max-height: 1100px !important;
    height: calc(100vh - 90px) !important;
}

.scrollbox-70 {
    overflow-y: auto !important;
    max-height: 70px !important;
}

.scrollbox-160 {
    overflow-y: auto !important;
    max-height: 160px !important;
}

.scrollbox-200 {
    overflow-y: auto !important;
    max-height: 200px !important;
}

.scrollbox-400 {
    overflow-y: auto !important;
    max-height: 400px !important;
}

.scroll-list {
    overflow-y: auto;
    max-height: calc(100% - 95px) !important;
}

.scrollable {
    overflow-y: auto;
}

.no-overflow {
    overflow: hidden !important;
}

.unset-overflow {
    overflow: unset !important;
}

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}
.mw-85 {
    max-width: 85% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100 {
    width: 100% !important;
}

.h-5 {
    height: 5% !important;
}

.h-10 {
    height: 20% !important;
}

.h-20 {
    height: 20% !important;
}

.h-30 {
    height: 30% !important;
}

.h-40 {
    height: 40% !important;
}

.h-50 {
    height: 50% !important;
}

.h-60 {
    height: 60% !important;
}

.h-70 {
    height: 70% !important;
}

.h-80 {
    height: 80% !important;
}

.h-90 {
    height: 90% !important;
}

.h-95 {
    height: 95% !important;
}
.h-100 {
    height: 100% !important;
}

.z-1 {
    z-index: 1 !important;
}

.z-2 {
    z-index: 2 !important;
}

.z-3 {
    z-index: 3 !important;
}

.z-4 {
    z-index: 4 !important;
}

.z-5 {
    z-index: 5 !important;
}

.z-10 {
    z-index: 10 !important;
}

.z-100 {
    z-index: 100 !important;
}

.z-250 {
    z-index: 250 !important;
}

.z-2000 {
    z-index: 2000 !important;
}

.pos-r {
    position: relative !important;
}

.pos-a {
    position: absolute !important;
}

.pos-f {
    position: fixed !important;
}

.f-1-2em {
    font-size: 1.2em !important;
}

.f-0-9em {
    font-size: 0.9em !important;
}

.op-0 {
    opacity: 0;
}

.action-btn {
    background: linear-gradient(0deg, rgba(24,116,216,1) 53%, rgba(104,163,209,1) 100%);
    width: 200px;
    border-radius: 6px;
    text-transform: unset !important;
    font-weight: bold !important;
}

.cancel-btn {
    background: linear-gradient(0deg, rgb(123,122,124), rgba(255,255,255) 200%);
}

.narrow-btn {
    width: unset !important;
    min-width: unset !important;
}

.close-modal-btn {
    top: 10px;
    right: 0%;
}

.toolbar {
    bottom: 24px;
    right: 24px;
    width: 41%;
    overflow-x: auto;
}

@media only screen and (max-width: 600px) {
    .toolbar {
        bottom: 0;
        right: 0px;
        width: 100%;
        max-width: 100%;
        height: 76px;
        margin: 0px;
    }
    .action-btn {
        width: 80%;
    }
}

/* .mob-toolbar {
    bottom: 0;
    right: 0px;
    width: 100%;
    max-width: 100%;
    height: 76px;
    margin: 0px;
} */

/* Vuetify Styles */

/* V-Main V-Application */

.v-main__wrap {
    background-color: #23262c !important;
}

.v-application {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    user-select: none;
    z-index: 0;
}

.v-toolbar__content {
    height: 64px !important;
}

/* V-Sheet */

.v-sheet.highlight-box,
.theme--dark.v-sheet.highlight-box {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 20px;
}

.v-sheet.highlight-box.transparent-bg,
.theme--dark.v-sheet.highlight-box.transparent-bg {
    background-color: transparent;
}

/* V-Buttons */

.v-btn__loader {
    color: #afb9c4 !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    color: #6A696E !important;
}

.v-btn__content {
    width: 100% !important;
}

.v-btn__content .theme--dark {
    text-shadow: 0 0 10px rgb(0 0 0 / 40%);
}

/* V-Selects */

.v-select__selection--comma {
    min-height: 1.1em;
}

.theme--light.v-select .v-select__selections {
    color: #FFFFFF !important;
}

.v-select__selections {
    color: #FFFFFF !important;
    background-color: transparent !important;
    font-weight: bold !important;
    font-size: 1.2em;
}

/* V-Icons */

.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
    color: #afb9c4 !important;
}

/* V-Lists */

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #afb9c4 !important;
}

.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    color: #FFFFFF !important;
}

.v-list-item {
    background-color: #4E4E52;
    color: #5F748A !important;
}
.v-list-item--active {
    background-color: #6A696E !important;
    color: #FFFFFF !important;
}

.v-list .v-sheet .theme--light {
    background-color: #38373d !important;
}

.theme--light.v-list {
    background-color: #38373d !important;
}

.v-list {
    padding: 0 0 0 0 !important;
}

.v-application--is-ltr .v-list-item__action:first-child {
    margin-right: 0 !important;
}

/* V-Cards */

.v-card__text {
    color: #FFFFFF !important;
}

.v-card__title {
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
}
.v-card__actions {
    background-color: rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

/* V-Expansions */

.v-expansion-panel-header {
    background-color: #4E4E52 !important;
    color: white !important;
}

.v-expansion-panel-content {
    background-color: #38373d !important;
    color: white !important;
}

/* V-Labels */

.theme--light.v-label {
    color: #FFFFFF !important;
}

/* V-Text Field */

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background-color: transparent !important;
}

/* V-Input */

.theme--light.v-input input, .theme--light.v-input textarea {
    color: #FFFFFF !important;
}

.theme--light.v-input input {
    color: #FFFFFF !important;
}
/* V-Divider */

.theme--dark.v-divider {
    border-color: #565558 !important;
}
</style>
