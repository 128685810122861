import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
        };
    },
    computed: {
        ...mapState(["seasonalThemeDetails"]),
    },
    created() {
        this.getSeasonalThemes();
        this.setupSeasonalThemes();
        setInterval(() => {
            this.getSeasonalThemes();
            this.setupSeasonalThemes();
        }, 1000 * 60); // every minute
    },
    watch: {
        seasonalThemeDetails: {
            handler() {
                this.setupSeasonalThemes();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(["getSeasonalThemes", "setCurrentSeasonalTheme"]),

        setupSeasonalThemes() {
            const currentSeasonalTheme = this.seasonalThemeDetails.find((theme) => this.withinDateRange(theme));
            this.setCurrentSeasonalTheme(currentSeasonalTheme);
        },

        withinDateRange({ start, end }) {
            if (!start || !end) {
                return false;
            }
            const today = new Date();
            const day = today.getDate();
            let month = today.getMonth();
            month += 1;

            const startDDMM = start.split("/");
            const endDDMM = end.split("/");
            const afterStart = month > Number(startDDMM[1]) || (month === Number(startDDMM[1]) && day >= Number(startDDMM[0]));
            const beforeEnd = month < Number(endDDMM[1]) || (month === Number(endDDMM[1]) && day <= Number(endDDMM[0]));

            if (afterStart && beforeEnd) {
                return true;
            }
            return false;
        },
    },
};
